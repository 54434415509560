import React, { useEffect, useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import { gsap } from 'gsap'
import ColorContext from '../context/ColorContext'
import { hexToRGB } from '../theme/themeAlopex'

const AKLogosMobile = () => {
  // Prepare Clients
  const { allClientsJson } = useStaticQuery(
    graphql`
      query {
        allClientsJson {
          nodes {
            title
            clientLogo
            desc
          }
        }
      }
    `
  )
  const clients = allClientsJson.nodes.map((c, i) => {
    return {
      title: c.title,
      clientLogo: c.clientLogo.replace('/static', ''),
      desc: c.desc
    }
  })

  const { color } = useContext(ColorContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState('Client')
  const [logo, setLogo] = useState('')
  const [desc, setDesc] = useState('')

  // Shuffle
  function shuffle(array) {
    let i = array.length
    let j = 0
    let temp
    while (i--) {
        j = Math.floor(Math.random() * (i+1))
        // swap randomly chosen element with current element
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
  }

  // Random Fill
  const randomFill = () => {
    const boxes = document.querySelectorAll('.logoGridMobile .item')

    // Shuffle
    shuffle(clients)

    // Assign shuffled clients to boxes and splice off
    // console.log('clients pre-slice', clients)
    for (let i = 0; i < boxes.length; i++) {
      gsap.set(boxes[i], {x: 0, y: 0, z: 0})
      boxes[i].style.backgroundImage = 'url(' + clients[i].clientLogo + ')'
      boxes[i].dataset.title = clients[i].title
      boxes[i].dataset.logo = clients[i].clientLogo
      boxes[i].dataset.desc = clients[i].desc
      clients.splice(i, 1)
    }
    // console.log('clients post-slice', clients)
    
    // Repeating timeline
    const tlRep = new gsap.timeline({repeat: -1, duration: 3})
    
    // Choose random client and block to assign
    let last = 0
    let rand = 1
    tlRep.call(() => {
      // console.log('clientsBefore', clients)
      do {
        rand = Math.floor(Math.random() * (boxes.length))
      } while (rand === last)
      let randLogo = Math.floor(Math.random() * (clients.length))
      const tlFlip = new gsap.timeline({repeat: 0})

      tlFlip.to(boxes[rand], 0.15, {rotationX: 90})
      tlFlip.to(boxes[rand], 0.15, {rotationX: 180})
      tlFlip.to(boxes[rand], 0.15, {rotationX: 270, backgroundColor: 'rgba(236, 253, 253, 0.95)'})
      tlFlip.set(boxes[rand], {backgroundImage: 'url(' + clients[randLogo].clientLogo + ')'})
      tlFlip.to(boxes[rand], 0.3, {rotationX: 360, backgroundColor: 'rgba(236, 253, 253, 0.25)'})
    
      // console.log('boxes, clients', boxes[rand].dataset.logo, clients[randLogo].clientLogo)
      last = rand
      clients.push({title: boxes[rand].dataset.title, clientLogo: boxes[rand].dataset.logo, desc: boxes[rand].dataset.desc})
      boxes[rand].dataset.title = clients[randLogo].title
      boxes[rand].dataset.logo = clients[randLogo].clientLogo
      boxes[rand].dataset.desc = clients[randLogo].desc
      clients.splice(randLogo, 1)
    })
  }

  const handleModal = (data) => {
    if (data.title !== '') {
      setTitle(data.title)
      setLogo(data.logo)
      setDesc(data.desc)
      onOpen()
    }
  }

  // Run the magic
  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.set(".item", {transformOrigin:"50% 50%"})
      randomFill()
    }
  }, [])
  
  return (
    <Box
      className="logoGridWrapper"
      sx={{
        '.logoGridMobile': {
          gridTemplateAreas: `
                              ". . a1"
                              "b1 b2 b2"
                              "c1 b2 b2"
                              "d1 d1 d2"
                              "d1 d1 e1"
                              "f1 f2 f3"
                              "g1 g2 ."
                              "h1 . ."
                             `,
          gridGap: '5px',
          '& .item': {
            // backgroundColor: '#37474f',
            paddingBottom: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '140% 140%',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: 'rgba(236, 253, 253, 0.25)',
            boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)',
            border: '1px solid rgba(236, 253, 253, 0.45)',
            transition: 'background-size 0.3s ease-in-out!important',
            '&:hover': {
              backgroundSize: '160% 160%'
            }
          },
          '.a1': { gridArea: 'a1' },
          '.b1': { gridArea: 'b1' },
          '.b2': { gridArea: 'b2' },
          '.c1': { gridArea: 'c1' },
          '.d1': { gridArea: 'd1' },
          '.d2': { gridArea: 'd2' },
          '.e1': { gridArea: 'e1' },
          '.f1': { gridArea: 'f1' },
          '.f2': { gridArea: 'f2' },
          '.f3': { gridArea: 'f3' },
          '.g1': { gridArea: 'g1' },
          '.g2': { gridArea: 'g2' },
          '.h1': { gridArea: 'h1' }
        }
      }}
    >
      <Box
        className="logoGridMobile"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridAutoFlow="row"
        margin="0 auto"
        maxWidth="640px"
        width="100%"
      >
        <div className="a1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="b1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="b2 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="c1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="d1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="d2 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="e1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="f1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="f2 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="f3 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="g1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="g2 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="h1 item" onClick={(e) => {handleModal(e.target.dataset)}}></div>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              margin="0 auto"
              sx={{
                img: {
                  maxWidth: '575px',
                  width: '100%'
                }
              }}
            >
              <img src={logo} />
              <p>{desc}</p>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Box display="none" mr={3} onClick={onClose}>
              Close
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <style>{`
        .chakra-modal__content {
          background-color: transparent;
          background-image: linear-gradient(135deg, ${hexToRGB(color.alpha, 0.75)}, ${hexToRGB(color.bravo, 0.75)});
          backdrop-filter: blur(4px);
          border: 1px solid rgba(255,255,255,0.1);
          color: white;
        }
      `}</style>
    </Box>
  )
}

export default AKLogosMobile