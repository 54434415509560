import React, { useEffect, useContext, useRef } from 'react'
import { graphql } from "gatsby"
import {
  Container,
  Box
} from '@chakra-ui/react'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import AKLogos from "../components/AKLogos"
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import AKLogosMobile from '../components/AKLogosMobile'
import ColorContext from '../context/ColorContext'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const ClientLogosPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  const refClouds = useRef()

  // HubSpot Embed Code
  const addExternalScript = (url) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
 };
 
  useEffect(()=>{
    addExternalScript("https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js")
  },[])


  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)

      gsap.to(refClouds.current, {
        x: '-520px',
        duration: 50,
        yoyo: true,
        repeat: -1,
        ease: 'none'
      })
    }
  }, [])

  return (
    <Layout>
      <SEO title={`K2 Aviation Branding & Website - ${data.site.siteMetadata.title}`} />

      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>Client Logos (Testing)</H1>
      </Container>

      <Box
        backgroundImage="url(/images/97345535_928444907598936_6704430101233543299_n.jpg)"
        backgroundSize="cover"
        marginTop="200px"
        boxShadow="inset 5000px 5000px 0 rgba(15, 51, 120, 0.5)"
      >
        <Box
          maxWidth="1200px"
          padding="6rem 1rem"
          margin="0 auto"
        >
          <AKLogos />
        </Box>
      </Box>

      {/* Pioneer Faded & Clouds */}
      <Box
        backgroundImage="url(/images/pioneer-fade.webp)"
        backgroundSize="cover"
        backgroundPosition="right top"
        position="relative"
        padding="220px 1rem 300px"
        sx={{
          '&::before': {
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '160px',
            bottom: '0',
            left: '0',
            backgroundImage: `linear-gradient(180deg, transparent, ${color.alpha})`
          }
        }}
      >
        <Box
          position="absolute"
          bottom="-225px"
          height="550px"
          width="100%"
          left="0"
          overflow="hidden"
        >
          <Box
            ref={refClouds}
            width="1264px"
            height="550px"
            position="absolute"
            left="-200px"
            top="0"
            backgroundImage="url(/images/clouds.webp)"
            backgroundPosition="left top"
            backgroundSize="contain"
          />
        </Box>
        <h6>Who is Alopex?</h6>
        <h4 style={{color:'white', maxWidth: '420px'}}>A Team of Alaskan creatives and programmers who build rich, award-winning, progressive marketing experiences.</h4>
        <p style={{color:'white', maxWidth: '420px'}}>We're located right here, in Alaska. We don't outsource any of our work. That means that our keyword management, bidding, budgeting and content creation is all done, here.</p>
      </Box>
      {/* Client Logos */}
      <Box
        backgroundColor={color.alpha}
        marginTop="-200px"
        marginBottom="100px"
        backgroundImage={`url(/images/pioneer-dark2.webp), linear-gradient(180deg, ${color.alpha}, ${color.alpha} 20%, ${color.bravo} 60%)`}
        backgroundPosition="right bottom, center"
        backgroundRepeat="no-repeat"
      >
        <Box
          maxWidth="1200px"
          padding="6rem 1rem 12rem"
          margin="0 auto"
        >
          <AKLogosMobile />
        </Box>
      </Box>

      <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/kyle941?embed=true"></div>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ClientLogosPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ClientLogosPage
