import React, { useEffect, useContext, createRef, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import { gsap } from 'gsap'
import ColorContext from '../context/ColorContext'
import { hexToRGB } from '../theme/themeAlopex'

const AKLogos = () => {
  // Prepare Clients
  const { allClientsJson } = useStaticQuery(
    graphql`
      query {
        allClientsJson {
          nodes {
            title
            clientLogo
            desc
          }
        }
      }
    `
  )
  const clients = allClientsJson.nodes.map((c, i) => {
    return {
      client: c.title,
      logo: c.clientLogo.replace('/static', ''),
      desc: c.desc
    }
  })

  const { color } = useContext(ColorContext)
  const refBoxes = useRef([])
  const refBigBoxes = useRef([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState('Client')
  const [logo, setLogo] = useState('')
  const [desc, setDesc] = useState('')

  const thirtyOne = []
  const nine = []
  for (let i = 0; i < 31; i++) thirtyOne.push('')
  for (let i = 0; i < 9; i++) nine.push('')
  refBoxes.current = thirtyOne.map(() => createRef())
  refBigBoxes.current = nine.map(() => createRef())

  // Shuffle
  function shuffle(array) {
    let i = array.length
    let j = 0
    let temp
    while (i--) {
        j = Math.floor(Math.random() * (i+1))
        // swap randomly chosen element with current element
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
  }

  // Random Fill
  const randomFill = (items) => {
    // Reset
    const allBoxes = document.querySelectorAll('.logoGrid .item')
    for (let i = 0; i < allBoxes.length; i++) {
      gsap.set(allBoxes[i], {backgroundImage: null, backgroundColor: null, x: 0, y: 0, z: 0})
      allBoxes[i].dataset.title = ''
      allBoxes[i].dataset.desc = ''
    }

    // Prep boxes and items
    const boxes = document.querySelectorAll('.logoGrid .item:not(.bigItem):not(.square)')
    const bigBoxes = document.querySelectorAll('.logoGrid .item.bigItem')
    const clients = [...items]
    shuffle(clients)
    
    // Splice out 9 random logos to ensure big boxes are filled
    const clientsSpliced = clients.splice(0, 6)
    // console.log('clientsSpliced', clientsSpliced)

    for (let i = 0; i < bigBoxes.length; i++) {
      bigBoxes[i].dataset.title = clientsSpliced[i].title
      bigBoxes[i].dataset.logo = clientsSpliced[i].logo
      bigBoxes[i].dataset.desc = clientsSpliced[i].desc
      const tl1 = new gsap.timeline({repeat:0, delay: i * 0.03})
      tl1.to(bigBoxes[i], 0.15, {rotationX:90})
        .set(bigBoxes[i], {backgroundImage: `url(${clientsSpliced[i].logo})`})
        .to(bigBoxes[i], 0.15, {rotationX:180})
        .to(bigBoxes[i], 0.3, {rotationX:360})
    }

    // Shuffle remaining logos among the remaining smaller boxes
    if (boxes.length > clients.length) {
      for (let j = 0; j < (boxes.length - clientsSpliced.length); j++) {
        clients.push('')
      }
      shuffle(clients)
    }

    // const shuffled2 = shuffle(shuffled)
    for (let k = 0; k < boxes.length; k++) {
      if (clients[k] !== '') {
        boxes[k].dataset.title = clients[k].title
        boxes[k].dataset.logo = clients[k].logo
        boxes[k].dataset.desc = clients[k].desc
        // Animate box locations
        const tl2 = new gsap.timeline({repeat:0, delay: k * 0.01})
        tl2.to(boxes[k], 0.15, {rotationX:90})
          .set(boxes[k], {backgroundImage: `url(${clients[k].logo})`})
          .to(boxes[k], 0.15, {rotationX:180})
          .to(boxes[k], 0.3, {rotationX:360})
      }
    }
  }

  const handleModal = (data) => {
    if (data.title !== '') {
      setTitle(data.title)
      setLogo(data.logo)
      setDesc(data.desc)
      onOpen()
    }
  }

  // Run the magic
  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.set(".item", {transformOrigin:"50% 50%"})
      gsap.to('.logoGrid', {
        repeat: -1,
        repeatDelay: 5,
        onStart: () => {
          randomFill(clients)
        },
        onRepeat: () => {
          randomFill(clients)
        }
      })
    }
  }, [])
  
  return (
    <Box
      className="logoGridWrapper"
      sx={{
        '.logoGrid': {
          gridTemplateAreas: `
                              ". . . . . . . a1 a1 . . . . . . ."
                              ". . . . . b1 b1 b2 b2 b3 b3 . . . . ."
                              ". . . c1 c1 c2 c3 c3 c3 c4 c4 c4 . . . ."
                              ". . . . d1 d1 c3 c3 c3 c4 c4 c4 . . . ."
                              ". . e1 . e2 e2 e2 e2 e2 e3 e3 e3 . . . ."
                              ". . . . e2 e2 e2 e2 e2 e3 e3 e3 . . . ."
                              ". . . g1 e2 e2 e2 e2 e2 g2 g2 g2 . . . ."
                              ". . h1 h1 h2 h2 h3 h3 h3 g2 g2 g2 h4 h4 . ."
                              ". . . . i1 i1 h3 h3 h3 . . i2 i2 i3 i3 ."
                              ". . . . . . j1 j2 j2 . . . . . j3 j3"
                              ". . . . k1 k1 k2 k2 . . . . . . . k3"
                              ". . l1 l1 l2 l2 . . . . . . . . . ."
                              "m1 m2 m2 . . . . . . . . . . . . ."
                             `,
          gridGap: '2px',
          '& .item': {
            // backgroundColor: '#37474f',
            paddingBottom: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '140% 140%',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: 'rgba(236, 253, 253, 0.35)',
            boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)',
            border: '1px solid rgba(236, 253, 253, 0.45)',
            transition: 'background-size 0.3s ease-in-out!important',
            '&:hover': {
              backgroundSize: '160% 160%'
            }
          },
          '@media only screen and (min-width: 640px)': {
            gridGap: '8px',
            '& .item': {
              borderRadius: '8px',
              boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(5px)',
            }
          },
          '.a1': { gridArea: 'a1' },
          '.b1': { gridArea: 'b1' },
          '.b2': { gridArea: 'b2' },
          '.b3': { gridArea: 'b3' },
          '.c1': { gridArea: 'c1' },
          '.c2': { gridArea: 'c2' },
          '.c3': { gridArea: 'c3' },
          '.c4': { gridArea: 'c4' },
          '.d1': { gridArea: 'd1' },
          '.e1': { gridArea: 'e1' },
          '.e2': { gridArea: 'e2' },
          '.e3': { gridArea: 'e3' },
          '.g1': { gridArea: 'g1' },
          '.g2': { gridArea: 'g2' },
          '.h1': { gridArea: 'h1' },
          '.h2': { gridArea: 'h2' },
          '.h3': { gridArea: 'h3' },
          '.h4': { gridArea: 'h4' },
          '.i1': { gridArea: 'i1' },
          '.i2': { gridArea: 'i2' },
          '.i3': { gridArea: 'i3' },
          '.j1': { gridArea: 'j1' },
          '.j2': { gridArea: 'j2' },
          '.j3': { gridArea: 'j3' },
          '.k1': { gridArea: 'k1' },
          '.k2': { gridArea: 'k2' },
          '.k3': { gridArea: 'k3' },
          '.l1': { gridArea: 'l1' },
          '.l2': { gridArea: 'l2' },
          '.m1': { gridArea: 'm1' },
          '.m2': { gridArea: 'm2' }  
        }
      }}
    >
      <Box
        className="logoGrid"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridAutoFlow="row"
        margin="0 auto"
        maxWidth="1280px"
        width="100%"
      >
        <div className="item bigItem c3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item bigItem c4" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item bigItem e2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item bigItem e3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item bigItem g2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item bigItem h3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item a1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item b1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item b2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item b3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item c1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item c2 square"></div>
        <div className="item d1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item e1 square"></div>
        <div className="item g1 square"></div>
        <div className="item h1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item h2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item h4" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item i1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item i2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item i3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item j1 square"></div>
        <div className="item j2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item j3" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item k1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item k2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item k3 square"></div>
        <div className="item l1" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item l2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
        <div className="item m1 square"></div>
        <div className="item m2" onClick={(e) => {handleModal(e.target.dataset)}}></div>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              margin="0 auto"
              sx={{
                img: {
                  maxWidth: '575px',
                  width: '100%'
                }
              }}
            >
              <img src={logo} />
              <p>{desc}</p>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Box display="none" mr={3} onClick={onClose}>
              Close
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <style>{`
        .chakra-modal__content {
          background-color: transparent;
          background-image: linear-gradient(135deg, ${hexToRGB(color.alpha, 0.75)}, ${hexToRGB(color.bravo, 0.75)});
          backdrop-filter: blur(4px);
          border: 1px solid rgba(255,255,255,0.1);
          color: white;
        }
      `}</style>
    </Box>
  )
}

export default AKLogos